<template>
  <div class="loan-faq">
    <script type="application/ld+json" v-html="ldJson" />
    <p class="title has-text-centered">Usein kysytyt kysymykset</p>
    <accordion :items="questions" :columns="2" />
  </div>
</template>

<script>
import accordion from '~/components/accordion'

export default {
  name: 'FAQAccordion',
  components: {
    accordion
  },
  data () {
    return {
      questions: [
        {
          title: 'Miksi kannattaa hakea lainaa Rahalaitoksen kautta?',
          content:
            'Rahalaitoksen kautta kilpailutat rahoitusyhtiöitä sekä pankkeja helposti ja nopeasti. Täyttämällä vain yhden hakemuksen, sinulla on mahdollisuus saada kerralla useampi tarjous vertailtavaksi. Lisäksi, lainan hakeminen ei velvoita sinua ottamaan lainaa ja Rahalaitoksen tarjoama vertailupalvelu on sinulle täysin ilmaista.'
        },
        {
          title: 'Mikä on lainan korko?',
          content:
            'Rahoitusyhtiöt tekevät itse lainapäätökset lainasummien sekä korkojen suhteen. He käyttävät apunaan mm. tulo- ja menoarviota, jolloin tarjoukset ovat luotettavia ja ottavat huomioon asiakkaan maksukyvyn. <a href="#interestRate">Esimerkkilaskurin</a> avulla pystyy selvittämään suuntaa antavan koron ja kk-erän.'
        },
        {
          title: 'Kuka voi hakea lainaa Rahalaitoksen kautta?',
          content:
            'Perusvaatimukset lainansaamiseen ovat säännölliset eläke– tai palkkatulot sekä puhtaat luottotiedot. Iältään lainanhakijan tulee olla vähintään 20–79-vuotias. Huomioithan, että voit hakea lainaa vain kerran kuukaudessa.'
        },
        {
          title: 'Onko lainan hakeminen maksutonta?',
          content:
            'Lainanhakeminen on täysin maksutonta, eikä se sido sinua mihinkään.'
        },
        {
          title: 'Miten voin alentaa nykyisten lainojen kustannuksia?',
          content:
            'Yhdistelylainan hakeminen kannattaa, mikäli sinulla on useampia pieniä lainoja. Hakemukselle täytetään kaikki nykyiset lainat sekä haluaako kyseisen lainan maksaa pois uudella lainalla. Yhdistelylainalla voi maksaa ja yhdistää pois korkeakorkoiset osamaksut, kulutusluotot sekä pienlainat aina 60 000 € saakka. Lyhentäessä yhtä lainaa tarvitsee muistaa vain yksi eräpäivä sekä kuukausierä. Lisäksi säästät koroissa ja lainanhoitokuluissa kun hoidat vain yhtä lainaa.'
        },
        {
          title: 'Mikä on yhteishakija ja onko siitä hyötyä?',
          content:
            'Yhteis- tai rinnakkaishakijalla tarkoitetaan henkilöä, joka hakee lainaa yhdessä päähakijan kanssa. Yhteishakijan kanssa lainan hakeminen mahdollistaa lähes aina edullisemmat lainatarjoukset sekä paremmat lainaehdot.'
        },
        {
          title: 'Tein virheen hakemukseen, miten pystyn korjaamaan sen?',
          content:
            `Paras ja nopein tapa virheen oikaisemiseen on soittaa asiakaspalveluumme p. <span class="has-text-link">09 2311 3670</span>. Näin saamme oikean tiedon myös lainanmyöntäjien tietoon. Voit olla yhteydessä asiakaspalveluumme myös chatin, Oma Rahalaitos – palvelun <a href="/oma/">${this.$domain}/oma</a>  tai sähköpostin <a href="mailto:asiakaspalvelu@rahalaitos.fi">asiakaspalvelu@rahalaitos.fi</a> kautta.`
        },
        {
          title: 'Mikä on Oma Rahalaitos -palvelu?',
          content:
            `Oma Rahalaitos –palvelu on portaali, jossa voi seurata hakemuksen käsittelyä reaaliajassa. Palvelussa näet kaikki saamasi lainatarjoukset, joita voit vertailla sekä saat ohjeet lainan nostamiseen. Omilla sivuilla <a href="/oma/">${this.$domain}/oma</a> pystyy myös keskustelemaan asiakaspalvelun kanssa hakemuksesta ja tarjouksista. Palveluun kirjaudutaan henkilötunnuksella ja salasanalla, joka lähetetään matkapuhelimeen ja sähköpostiin hakemuksen täyttämisen jälkeen. Siksi on erityisen tärkeää, että yhteystiedot on kirjattu hakemukselle oikein.`
        },
        {
          title: 'En pääse kirjautumaan Oma Rahalaitos –palveluun?',
          content:
            'Oma Rahalaitos –palveluun kirjaudutaan hakemuksen täyttämisen jälkeen. Palvelu on avoinna koko hakemuksen voimassaoloajan. Mikäli kirjautuminen epäonnistuu, kehotamme olemaan yhteydessä asiakaspalveluumme puhelimitse <span class="has-text-link">09 2311 3670</span> tai chatin kautta.'
        },
        {
          title: 'Miten lainapäätös tehdään ja mitkä asiat siihen vaikuttavat?',
          content:
            'Rahoitusyhtiöt käsittelevät hakemuksen aina asiakaskohtaisesti. Lainatarjoukseen vaikuttaa positiivisesti mm. moitteeton maksuhistoria sekä vakituiset tulot tai eläke. Helpoin tapa löytää paras lainatarjous on täyttää maksuton <a href="#main-hero">lainahakemus</a> sivuillamme.'
        },
        {
          title: 'Voiko lainan maksaa aiottua nopeammin pois?',
          content:
            'Ylimääräisiä suorituksia voi tehdä milloin vain, jolloin takaisinmaksuaika lyhenee. Voit myös maksaa koko lainan yhdessä erässä pois. Korkoa maksetaan ainoastaan siltä ajalta, kun laina on ollut käytössä. Ennenaikaisesta takaisinmaksu järjestelystä kannattaa sopia aina rahoitusyhtiön kanssa, josta lainan on ottanut.'
        },
        {
          title: 'Kuinka usein voin hakea lainaa?',
          content:
            `Voit kilpailuttaa lainan uudestaan kuukauden kuluttua edellisestä hakemuksesta. Lainahakemus sekä suurin osa lainatarjouksista ovat voimassa 30 päivää ja tämän vuoksi emme voi käsitellä uutta hakemusta aiemmin. Mikäli haluat tehdä muutoksia lainahakemuksellesi, olethan suoraan yhteydessä asiakaspalveluumme, puh 09 2311 3670. Voit olla yhteydessä asiakaspalveluumme myös chatin, Oma Rahalaitos – palvelun https://${this.$domain}/oma kautta tai lähettää meille sähköpostia osoitteeseen asiakaspalvelu@rahalaitos.fi`
        }
      ]
    }
  },
  computed: {
    ldJson () {
      const json = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: []
      }
      this.questions.forEach( ( question ) => {
        json.mainEntity.push( {
          '@type': 'Question',
          name: question.title,
          acceptedAnswer: {
            '@type': 'Answer',
            text: question.content.replace( /<[^>]*>?/gm, '' )
          }
        } )
      } )
      return JSON.stringify( json )
    }
  }
}
</script>

<style lang="scss">
.loan-faq .title {
  font-size: 28px;
  text-align: left !important;
}
</style>
