<template>
  <div>
    <template v-if="showMiniform">
      <span class="miniform-header">{{ miniform.title }}</span>
      <miniform :go-to-url="miniform.url" />
    </template>
    <template v-if="showSliders">
      <span class="miniform-header">{{ sliders.title }}</span>
      <sliders :go-to-url="sliders.url" :business-loan="sliders.isBusiness" :luottoraja="sliders.amount" :maksuaika="sliders.period" />
    </template>
  </div>
</template>

<script>
export default {
  components: {
    miniform: () => import('~/components/subpage/miniform'),
    sliders: () => import('~/components/subpage/sliders')
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      showMiniform: false,
      miniform: {
        title: 'Lainan kilpailutus 60 000 € asti',
        url: null
      },
      showSliders: false,
      sliders: {
        title: 'Lainan kilpailutus 60 000 € asti',
        amount: undefined,
        period: undefined,
        url: '/',
        isBusiness: false
      }
    }
  },
  mounted () {
    // Parse data
    Object.keys(this.data).forEach(component => {
      const comp = this.data[component]
      if (comp.__component === 'sliders.kulutusluotto-minihakemus') {
        this.showMiniform = true
        if (comp.title) this.miniform.title = comp.title
        this.miniform.url = comp.goToUrl ? comp.GoToUrl : undefined
      }
      else if (comp.__component === 'sliders.sliderit') {
        this.showSliders = true
        if (comp.title) this.sliders.title = comp.title
        if (comp.isBusiness) this.sliders.isBusiness = true
        if (comp.url) this.sliders.url = comp.url
        if (comp.amount) this.sliders.amount = comp.amount
        if (comp.period) this.sliders.period = comp.period
      }
    })
  }
}
</script>
