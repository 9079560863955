<script>
import faq from '~/components/layout/faq'

export default {
  name: 'YhdistaFAQAccordion',
  extends: faq,
  data () {
    return {
      questions: [
        {
          title: 'Kenelle lainan yhdistäminen sopii?',
          content:
            `Lainojen yhdistäminen sopii henkilöille, joille on kertynyt useita eri lainoja. Kun yhdistät lainasi voit
            yhdistää esimerkiksi luottokorttivelat, osamaksusopimukset, kulutusluotot ja pienlainat yhdeksi isommaksi
            lainaksi. Lainojen yhdistäminen voi tuoda merkittäviä säästöjä erilaisten hallintopalkkioiden ja
            tilinhoitomaksujen poistuessa. Saat myös todennäköisesti edullisemman koron, kun yhdistät lainasi yhdeksi
            suuremmaksi lainaksi useamman eri pienlainan sijaan.`
        },
        {
          title: 'Mitä kannattaa huomioida, kun yhdistän lainani?',
          content:
            `Kun lainat yhdistetään ja kaikki pienet lainat maksetaan pois, kannattaa miettiä mikä on oikea kuukausierä
            maksettavaksi niin että elämän muista menoista selviää järkevästi. Joskus laina-aika pitenee lainoja
            yhdistettäessä. Jos maksettava kuukausierä pienenee, lainan maksaminen kestää pitempään, vaikka korko
            laskeekin. Usein tämä on kuitenkin pidemmällä tähtäimellä hyvä asia, jotta rahaa jää kuukausittain käyttöön
            enemmän. Kun pakolliset menot saa kuukausittain hoidettua, pystyy elämän suunnitteluun ja oman talouden
            parantamiseen keskittymään paremmin.<br><br>Jotta pankit tai rahoitusyhtiöt tarjoavat lainojen yhdistämistä,
            on luottotietojen oltava kunnossa, ja hakijalla on oltava säännölliset kuukausitulot. Me Rahalaitoksella
            kilpailutamme yhdistelylainoja usealta eri pankilta. Vertailemalla lainojen yhdistämistä varten saamiasi
            lainatarjouksia kauttamme pidät huolen siitä, että löydät parhaimman mahdollisen lainan, jolla yhdistellä
            kaikki velkasi.`
        },
        {
          title: 'Miten hyödyn lainojen yhdistämisestä?',
          content:
            `Pienissä lainoissa on yleensä korkeampi korko kuin suuremmissa. Lainat yhdistämällä voit saada selvästi
            alhaisemman koron kuin jos sinulla on useita pieniä lainoja. Lainan koron lisäksi myös kuukausittain
            maksettavat tilinhoitomaksut tai vastaavat kuukausikulut maksetaan yleensä saman suuruisina, oli kyseessä
            sitten pienempi tai suurempi laina. Yhdestä yhdistetystä lainasta maksetaan luonnollisesti vain yksi
            tilinhoitomaksu ja tämä voi tuoda merkittävänkin säästön, kun maksettavana on vain yksi lasku Säästät myös
            aikaasi – et joudu muistamaan erikseen useita eräpäiviä ja käyttämään aikaa monen eri laskun maksamiseen.
            Oman talouden hoitaminen ja seuranta myös helpottuu, kun lyhennettävänä on vain yksi yhdistetty laina.`
        },
        {
          title: 'Voinko yhdistää lainat nykyisessä pankissani?',
          content:
            `Myös perinteiset pankit tarjoavat lainojen yhdistämistä asiakkailleen. Perinteiset pankit eivät ole yleensä
            keskittyneet niin vahvasti yhdistelylainojen myöntämiseen. Siksi voi hyvinkin olla, että saat lainasi
            yhdistettyä edullisemman Rahalaitoksen yhteistyökumppaneilla. Lainojen yhdistäminen on kumppaneidemme
            ydinliiketoimintaa ja he kilpailevat hyvistä asiakkaista palvelussamme.`
        },
        {
          title: 'Mikä on yhdistettyjen lainojen korko?',
          content:
            `Korko määräytyy hakemasi lainasumman, oman maksukykysi sekä lainojen yhdistämistä tarjoavan pankin tai
            rahoituslaitoksen mukaan. Mitä suurempaa lainasummaa haet, sitä matalampi korko saadaan lainat yhdistämällä.
            Rahalaitoksen kumppaneina toimivat lainantarjoajat voivat myöntää yhdistettyä lainaa alimmillaan 4,5 %
            korolla. Mahdollisuuksien mukaan lainahakemus kannattaa täyttää yhdessä rinnakkaishakijan kanssa, jolloin
            pankit voivat myöntää pienemmän koron lainoja yhdistettäessä.<br><br>Rahalaitoksen palvelun avulla saat
            lainatarjoukset nopeasti, jopa muutamassa minuutissa. Voit tutustua tarjouksiin kaikessa rauhassa ja valita
            itsellesi parhaan vaihtoehdon lainojen yhdistämiseen helposti.`
        },
        {
          title: 'Voiko mitä tahansa lainoja yhdistää?',
          content:
            `Rahalaitoksen avulla voit yhdistää haluamasi lainat, luotot tai osamaksusopimukset aina 60 000 euroon asti.
            Lainojen yhdistämisen avulla saat kulutusluotoista, pienlainoista, luottokorteista tai osamaksusopimuksista
            kertyvät korot ja lainakustannukset kuriin. Asuntolainat jätetään yleensä lainojen yhdistämisen
            ulkopuolelle, koska niiden korko on yleensä alhaisempi kuin vakuudettomissa lainoissa.`
        }
      ]
    }
  }
}
</script>
