<template>
  <div>
    <main-hero
      title="Hae rahoitus etutuotteen hankintaan"
      subtitle="Kilpailuta edullinen laina valitsemasi tuotteen ostoon"
      side-title="Hae rahoitus ostoon tästä!"
      :side-boxes="sideBoxes"
      survey-title="Asiakkaamme arvioivat"
    />
    <section class="hero has-background-rlgray">
      <div class="hero-body">
        <div class="container">
          <h2 class="title has-text-white is-size-4 has-text-centered">
            Kilpailuta tarvitsemasi laina 60 000 euroon asti
          </h2>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <loan-steps />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <banks />
        </lazy-hydrate>
      </div>
    </section>
    <section class="hero is-light">
      <div class="hero-body">
        <div class="container">
          <lazy-hydrate when-visible>
            <feedback />
          </lazy-hydrate>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <faq />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <p class="title has-text-centered">Artikkelit</p>
      <div class="container">
        <lazy-hydrate when-visible>
          <subpages />
        </lazy-hydrate>
        <p
          class="title"
          style="text-align: right; font-size: 1.1rem; margin-top: 15px;"
        >
          <nuxt-link to="/talous" class="has-text-dark">
            Lisää artikkeleita
            <font-awesome-icon :icon="['fas', 'angle-right']" />
          </nuxt-link>
        </p>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <terms loan-type="consumer" />
        </lazy-hydrate>
      </div>
    </section>
    <hero-action />
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'


export default {
  name: 'Etutuoterahoitus',
  components: {
    LazyHydrate,
    banks: () => import( '~/components/layout/banks' ),
    faq: () => import( '~/components/layout/faq' ),
    feedback: () => import( '~/components/layout/feedback' ),
    heroAction: () => import( '~/components/layout/heroAction' ),
    loanSteps: () => import( '~/components/layout/loanSteps' ),
    mainHero: () => import( '~/components/layout/mainHero' ),
    subpages: () => import( '~/components/layout/subpages' ),
    terms: () => import( '~/components/layout/terms' )
  },
  data () {
    return {
      sideBoxes: [
        {
          icon: () => import( '~/assets/img/icons/banks.svg?inline' ),
          title: 'Kilpailuta jopa',
          subtitle: '25 pankkia'
        },
        {
          icon: () => import( '~/assets/img/icons/file-contract-light.svg?inline' ),
          title: 'Lainan nostaminen ei',
          subtitle: 'sido ostoon'
        },
        {
          icon: () => import( '~/assets/img/icons/shield-check-light.svg?inline' ),
          title: 'Valitse lainasumma',
          subtitle: 'ja laina-aika'
        }
      ]
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Lainaa ilman takaajia',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/lainaa-ilman-takaajia'
      },
      {
        title: 'Lainaa 30 000 euroa',
        subtitle: 'Suuriin ja pieniin unelmiin',
        link: '/lainaa-30000-euroa'
      },
      {
        title: 'Yhdistä lainasi',
        subtitle: 'Selkiytä talouttasi ja säästä kuluissa',
        link: '/yhdista-lainat'
      }
    ] )
  },
  head () {
    return {
      title: 'Hae rahoitus etutuotteen hankintaan | Rahalaitos',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Kilpailuta edullinen laina valitsemasi tuotteen ostoon. Täytä hakemus ilmaiseksi jo tänään!'
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex'
        }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi/etutuoterahoitus' }
      ]
    }
  }
}
</script>
